<template>
  <div>
    <v-sheet
    class="mt-0 mb-2 ml-0 mr-0 pt-2 pb-0"
    elevation="8"
    color="grey darken-3"
    rounded
    
  >
    <v-slide-group
      class="white--text"
      dark
      show-arrows
      
      
    >
      <v-slide-item
        v-for="(item, i) in items"
        :key="i"
        v-slot="{ active}"
      >
        <v-btn
          class="mb-2 ml-2"
          :input-value="active"
          small
          depressed
          rounded
          @click="$router.push(item.ruta)"
        >
        <span class="text-center text-caption white--text"
                ><v-icon>{{item.icon}}</v-icon> {{item.text}}</span
              >
        </v-btn>
      </v-slide-item>
    </v-slide-group>
    </v-sheet>
  </div>
</template>

<script>
//import axios from "axios";
export default {
  data() {
    return {
      dialogm1: 0,
      dialog: true,
      dialogMC: false,
      items: [
        {
          text: "HIPISMO 24/7",
          icon: "mdi-horse-variant",
          ruta: "/virtuales/horse",
        },
        { text: "GALGOS 24/7", icon: "mdi-dog-side", ruta: "/virtuales/dog8" },
        /* { text: "CARRETAS 24/7", icon: "mdi-horse-variant", ruta:"/virtuales/harness" }, */
        { text: "KARTS 24/7", icon: "mdi-car-sports", ruta: "/virtuales/kart" },
        { text: "RULETA 24/7", icon: "mdi-poker-chip", ruta: "/virtuales/rtt" },
        { text: "KICKBOX 24/7", icon: "mdi-karate", ruta: "/virtuales/wgp" },
      ],
    };
  },
  methods: {},
  computed: {
    size() {
      return this.$store.state.windowSize.x;
    },
    dialogProgramas: {
      get() {
        return this.$store.state.dialogProgramas;
      },
      set(value) {
        this.$store.state.dialogProgramas = value;
      },
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-bar {
  width: 80%;
  max-width: 158px;
  position: fixed;
  left: 0;
  top: 74%;
}

.fa-play {
  cursor: pointer;
  transition: all 0.4s;
  font-size: 12px;
  margin-bottom: 5px;
  display: inline-block;
  transform: rotate(180deg);
  color: #49b0e6;
}
#btn-social:checked ~ .fa-play {
  transform: rotate(0deg);
}
.icon-social {
  transition: all 0.4s;
  transform: translateX(-7%);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
}
#btn-social:checked ~ .icon-social {
  transform: translateX(-70%);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0);
}

.v-tab {
  left: 0;
  margin: 0px -14px;
}
</style>
