<template>
  <div>
    <form id="frmregister" @submit.prevent="submitForm" autocomplete="nunca">
      <v-container style="background: #fff">
        <v-row class="px-0 mx-0">
          <v-col cols="12" md="4">
            <v-text-field
              :color="colors.perfil"
              autofocus
              class="mt-0 mb-3"
              label="E-mail"
              autocomplete="nunca"
              :rules="[rules.required, rules.emailValid]"
              dense
              outlined
              flat
              background-color="blue-grey lighten-5"
              prepend-inner-icon="mdi-email"
              v-model="emailRe"
              required
              type="email"
            ></v-text-field>
            <v-text-field
              :color="colors.perfil"
              v-model="passRe"
              background-color="blue-grey lighten-5"
              prepend-inner-icon="mdi-lock"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              autocomplete="off"
              dense
              outlined
              flat
              label="Contraseña"
              class="mt-0 mb-3"
              hint="Minimo 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-0 mx-0">
          <v-col cols="12" md="4">
            <v-text-field
              :color="colors.perfil"
              autocomplete="off"
              class="mt-0 mb-3 register"
              label="Nombre y Apellido"
              single-line
              dense
              solo
              flat
              background-color="blue-grey lighten-5"
              hide-details
              prepend-inner-icon="mdi-account-circle"
              v-model="nombre"
              required
            ></v-text-field>
            
            <v-text-field
              :color="colors.perfil"
              autocomplete="off"
              class="mb-2 register"
              label="# Documento de Indentidad"
              single-line
              dense
              solo
              flat
              background-color="blue-grey lighten-5"
              hide-details
              prepend-inner-icon="mdi-card-account-details"
              v-model="dniRe"
              required
              type="text"
            ></v-text-field>

            <v-text-field
              :color="colors.perfil"
              autocomplete="off"
              class="mb-3 register"
              label="F. Nacimiento"
              single-line
              dense
              solo
              flat
              background-color="blue-grey lighten-5"
              hide-details
              prepend-inner-icon="mdi-calendar"
              v-model="nacimientoRe"
              required
              type="date"
            ></v-text-field>
            <vue-tel-input
              @input="onInput"
              :required="true"
              :preferred-countries="['ve']"
              class="mb-3 mr-3 phone"
              placeholder="Teléfono"
              v-model="phoneRe"
            ></vue-tel-input>
            <v-select
              :color="colors.perfil"
              dense
              flat
              solo
              v-model="select"
              background-color="blue-grey lighten-5"
              :items="items"
              item-text="state"
              item-value="value"
              label="Moneda"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-col>
          <div v-if="error">
                      <v-alert dense class="mb-0 mt-3" type="error"
                        >Verifique los datos ingresados</v-alert
                      >
          </div>
          <v-snackbar color="white" v-model="snackbar">
          <b class="green--text">{{ $store.state.msg }}</b>
          <v-btn text dark @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>

          
          <v-btn
            class="mb-2 mt-8"
            :color="colors.perfil"
            :loading="loading"
            depressed
            dark
            block
            type="submit"
            >REGISTRARME</v-btn
          >
        </v-row>
      </v-container>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      gameUrl: null,
      overlay: true,
      isMobile: 0,
      iframeUpdate: false,
      multiplicador: 1,
      mon: null,
      screenHeight: 0,
      select: { state: "Bolivares", value: 1 },
      items: [
        { state: "Bolivares", value: 1 },
        { state: "Dolares", value: 2 },
        { state: "Soles", value: 3 },
      ],

      snackbar: false,
      snackbarVisible: false,
      drawer: false,
      done: false,
      
      loading: false,
      nombre: "",
      apellido: "",
      dniRe: "",
      emailRe: "",
      phoneRe: "",
      rePais: "",
      reCuidad: "",
      passRe: "",
      promoCode: "",
      referido: "",
      passConRe: "",
      nacimientoRe: "",
      checkboxMayor: true,
      checkboxCond: true,
      email: "",
      pass: "",
      error: false,
      dominio: null,
      min: "2:00",
      interval: {},
      codeSMS: null,
      id: null,
      waiting: false,
      doneReset: false,
      telReset: [],
      emialReset: "",
      passReset: "",
      confPassReset: "",
      invalid: false,
      sms: false,
      moneda: "",
      deferredPrompt: null,
      saldoSelected: 0,
      mensaje: "",
      show1: false,
      rules: {
        required: (value) => !!value || "Requerido.",
        min: (v) => v.length >= 8 || "Min 8 Caracteres",
        emailMatch: () => `The email and password you entered don't match`,
        emailValid: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Ingrese un Email valido",
      },
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.screenHeight = window.innerHeight;
    this.isMobile = window.innerWidth < 600 ? 1 : 0;
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
   
  },
  methods: {
    ...mapActions(["getSaldo"]),
    onResize() {
      this.isMobile = window.innerWidth < 600 ? 1 : 0;
    },
    onInput(formattedNumber, { number }) {
      this.phoneRe = number.international;
      this.telRe = number.significant;
    },
    submitForm(e) {

      
      
      e.preventDefault();
      
      if (
        this.passRe != ""
      ) {
        
        
        let split = this.phoneRe.split("+")[1];
        let area = split.split(" ")[0];
        let phone = this.telRe;

        this.loading = true;
        
        
        axios({
          method: "post",
          url: `https://apitr.xyz/onlineapi/api/web_register_nav`,
          data: {
            name: this.nombre + " " + this.apellido,
            email: this.emailRe,
            area: area,
            telefono: phone,
            clave: this.passRe,
            dominio: this.dominio,
            idpais: this.select,
            pais: "Navegante",
            ciudad: "Caracas",
            dni: this.dniRe,
            f_nacimiento: this.nacimientoRe,
            promocode: this.promoCode,
            referido: this.referido,
          },
        }).then((response) => {
          if (response.data.message == "Mensaje enviado") {
            alert("Usuario creado correctamete");
            
          } else {
            this.$store.state.msg = response.data.message;
            this.snackbar = true;
          }
          this.loading = false;
        });
      } else {
        this.error = true;
      }
    }
  },
  computed: {
    idmoneda() {
      return this.$store.state.idmoneda;
    },
    idsb() {
      return this.$store.state.idsb;
    },
    currentBalance() {
      return this.$store.state.currentBalance;
    },
    dialogProgramas: {
      get() {
        return this.$store.state.dialogProgramas;
      },
      set(value) {
        this.$store.state.dialogProgramas = value;
      },
    },
    idpos: {
      get() {
        return this.$store.state.idpos;
      },
      set(e) {
        this.$store.state.idpos = e;
      },
    },
    size() {
      return this.$store.state.windowSize.x;
    },
    routeName() {
      return this.$route.name;
    },
    image() {
      return this.$store.state.image;
    },
    logo() {
      return this.$store.state.logo;
    },
    colors() {
      return this.$store.state.colors;
    },
    emailtr() {
      return this.$store.state.email;
    },
    marca() {
      return this.$store.state.marca;
    },
    cssProps() {
      return {
        "--table-head0": this.colors.gradient ? this.colors.gradient[0] : "",
        "--table-head1": this.colors.gradient ? this.colors.gradient[1] : "",
        "--select-0": this.colors.gradientBarra
          ? this.colors.gradientBarra[0]
          : "",
        "--select-1": this.colors.gradientBarra
          ? this.colors.gradientBarra[1]
          : "",
        "--table-back": this.colors.panelbar,
        "--table-even": this.colors.even,
      };
    },
  },
};
</script>

<style>
header {
  background-color: #fa22229a;
  border-radius: 5px;
  color: #fff;
  padding: 1px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 3px;
}

nav ul {
  list-style: none;
  padding: 3px;
  margin: 0;
}

nav li {
  display: inline; /* Hace que los ítems del menú estén en una línea horizontal */
  margin-right: 20px; /* Espacio entre los ítems del menú */
}

nav a {
  text-decoration: none;
  color: #fff !important;
  font-size: 16px;
}

nav a:hover {
  font-size: 17px;
  color: #000 !important;
}
</style>
