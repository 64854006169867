<template>
  <v-card
    v-if="colors.gradient"
    class="eventos"
    :color="colors.sidebar"
    flat
    dark
  >
    <div
      class="appbar-shadow"
      :style="
        `background: linear-gradient(0deg, ${colors.gradient[1]} 0%, ${colors.gradient[0]} 100%);height:46px;border-radius:5px`
      "
    >
      <!-- <v-card-title class="px-1 pa-0" style="padding-top:2px  !important">
				<h5 class="pa-0 ml-3 white--text font-weight-medium">EVENTOS DEL DIA</h5>
			</v-card-title>-->
      <v-tabs
        class="ma-0"
        v-model="tab"
        slider-color="#ff2128"
        slider-size="2"
        grow
        background-color="transparent"
        height="46px"
      >
        <v-tab>
          <v-img
            src="../../assets/img/hipo.svg"
            aspect-ratio="1"
            max-width="30px"
          ></v-img>
        </v-tab>
        <v-tab>
          <v-img
            src="../../assets/img/galgos.svg"
            aspect-ratio="1"
            max-width="30px"
          ></v-img>
        </v-tab>
        <v-tab>
          <v-img
            src="../../assets/img/carretas.svg"
            aspect-ratio="1"
            max-width="30px"
          ></v-img>
        </v-tab>
      </v-tabs>
    </div>
    <!-- <v-divider></v-divider> -->
    <v-card-text class="pt-0 px-0">
      <v-tabs-items v-model="tab" style="background: transparent">
        <v-tab-item>
          <v-list
            nav
            rounded
            dark
            two-line
            dense
            flat
            color="transparent"
            class="event-list"
          >
            <template v-if="hipodromos.length != 0">
              <v-list-item-group no-action v-model="item" color="white">
                <template v-for="(item, idx) in hipodromos">
                  <v-list-item
                    :key="idx"
                    v-if="item.estatus != 'CERRADA'"
                    class="event-item"
                    @click="changeEvent(item, true, idx, 0)"
                  >
                    <v-list-item-avatar size="32">
                      <v-tooltip
                        v-if="item.canal != '' && item.estatus != 'CERRADA'"
                        color="red darken-1"
                        dark
                        v-model="shown"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-if="
                              $store.state.currentBalance.toLocaleString() <
                                '0.00'
                            "
                          >
                            <v-btn
                              v-bind="attrs"
                              @mouseenter="shown = true"
                              @mouseleave="shown = false"
                              v-if="
                                item.canal != '' && item.estatus != 'CERRADA'
                              "
                              color="white"
                              @click="shown = true"
                              style="
																z-index: 4;
																border: 3px solid #eb262c;
																background: white;
															"
                              icon
                            >
                              <v-icon color="#eb262c">mdi-play</v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn
                              @mouseenter="hover = true"
                              @mouseleave="hover = false"
                              v-if="
                                item.canal != '' && item.estatus != 'CERRADA'
                              "
                              color="white"
                              @click="openCanal(item.canal)"
                              style="
																z-index: 4;
																border: 3px solid #eb262c;
																background: white;
															"
                              icon
                            >
                              <v-icon color="#eb262c">mdi-play</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span
                          >Por favor recargue su saldo para ver los canales en
                          vivo</span
                        >
                      </v-tooltip>
                      <v-img
                        v-else-if="item.tipo == 'HIPODROMO'"
                        src="../../assets/img/hipo.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'GALGODROMO'"
                        src="../../assets/img/galgos.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'CARRETA'"
                        src="../../assets/img/carretas.svg"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="mb-0">
                        <b class="font-weight-medium caption">{{
                          item.nombre
                        }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle style="line-height: 0.7rem">
                        <small class="d-flex align-center">
                          <span class="font-weight-medium"
                            >CARRERA {{ item.idcarrera }}</span
                          >
                          <span class="ml-1" v-if="item.mtp != 0">-</span>
                          <span
                            v-if="
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            "
                            class="white--text ml-1 font-weight-medium"
                            :class="item.mtp == 0 ? 'animate-mtp' : ''"
                            >[ {{ item.mtp }} MTP ]</span
                          >
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <div class="d-flex align-center">
                        <v-chip
                          :class="
                            item.estatus == 'CORRIENDO' ? 'black--text' : ''
                          "
                          x-small
                          dark
                          :color="color(item.estatus)"
                          style="padding: 10px 10px"
                        >
                          <b
                            :style="
                              marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : ''
                            "
                            class="font-weight-medium"
                          >
                            <small>{{ item.estatus }}</small>
                          </b>
                        </v-chip>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- <v-divider inset :key="item.nombre"></v-divider> -->
                </template>
              </v-list-item-group>
              <!-- EVentos 24/7 -->
              <v-list-item-group
                
                no-action
                v-model="selectedItem"
                color="white"
              >
                <v-list-item
                  class="event-item"
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <v-list-item-icon class="mt-3">
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    class="py-0"
                    @click.stop="$router.push(item.ruta)"
                  >
                    <v-list-item-title
                      class="mt-0  mb-0"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="d-flex align-center">
                      <v-chip
                        x-small
                        dark
                        color="green"
                        style="padding: 10px 10px"
                      >
                        <b>
                          24 / 7
                        </b>
                      </v-chip>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
              <!--  Eventos Cerrados -->
              <!--  Eventos Cerrados -->
              <!--  Eventos Cerrados -->
              <v-list-item-group no-action v-model="item" color="white">
                <template v-for="(item, idx) in hipodromos">
                  <v-list-item
                    :key="idx"
                    v-if="item.estatus == 'CERRADA'"
                    class="event-item"
                    @click="changeEvent(item, true, idx, 0)"
                  >
                    <v-list-item-avatar size="32">
                      <v-tooltip
                        v-if="item.canal != '' && item.estatus != 'CERRADA'"
                        color="red darken-1"
                        dark
                        v-model="shown"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-if="
                              $store.state.currentBalance.toLocaleString() <
                                '1.00'
                            "
                          >
                            <v-btn
                              v-bind="attrs"
                              @mouseenter="shown = true"
                              @mouseleave="shown = false"
                              v-if="
                                item.canal != '' && item.estatus != 'CERRADA'
                              "
                              color="white"
                              @click="shown = true"
                              style="
																z-index: 4;
																border: 3px solid #eb262c;
																background: white;
															"
                              icon
                            >
                              <v-icon color="#eb262c">mdi-play</v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn
                              @mouseenter="hover = true"
                              @mouseleave="hover = false"
                              v-if="
                                item.canal != '' && item.estatus != 'CERRADA'
                              "
                              color="white"
                              @click="openCanal(item.canal)"
                              style="
																z-index: 4;
																border: 3px solid #eb262c;
																background: white;
															"
                              icon
                            >
                              <v-icon color="#eb262c">mdi-play</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span
                          >Por favor recargue su saldo para ver los canales en
                          vivo</span
                        >
                      </v-tooltip>
                      <v-img
                        v-else-if="item.tipo == 'HIPODROMO'"
                        src="../../assets/img/hipo.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'GALGODROMO'"
                        src="../../assets/img/galgos.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'CARRETA'"
                        src="../../assets/img/carretas.svg"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="mb-0">
                        <b class="font-weight-medium caption">{{
                          item.nombre
                        }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle style="line-height: 0.7rem">
                        <small class="d-flex align-center">
                          <span class="font-weight-medium"
                            >CARRERA {{ item.idcarrera }}</span
                          >
                          <span class="ml-1" v-if="item.mtp != 0">-</span>
                          <span
                            v-if="
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            "
                            class="white--text ml-1 font-weight-medium"
                            :class="item.mtp == 0 ? 'animate-mtp' : ''"
                            >[ {{ item.mtp }} MTP ]</span
                          >
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <div class="d-flex align-center">
                        <v-chip
                          :class="
                            item.estatus == 'CORRIENDO' ? 'black--text' : ''
                          "
                          x-small
                          dark
                          :color="color(item.estatus)"
                          style="padding: 10px 10px"
                        >
                          <b
                            :style="
                              marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : ''
                            "
                            class="font-weight-medium"
                          >
                            <small>{{ item.estatus }}</small>
                          </b>
                        </v-chip>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- <v-divider inset :key="item.nombre"></v-divider> -->
                </template>
              </v-list-item-group>
            </template>
            <template v-else>
              <div class="d-flex">
                <div class="mx-auto">
                  <h3>No hay eventos disponibles</h3>
                </div>
              </div>
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-list
            nav
            rounded
            dark
            two-line
            dense
            color="transparent"
            class="event-list"
          >
            <template v-if="galgos.length != 0">
              <v-list-item-group no-action v-model="item1" color="white">
                <template v-for="(item, idx) in galgos">
                  <v-list-item
                    :key="idx"
                    class="event-item"
                    @click="changeEvent(item, true, idx, 1)"
                  >
                    <v-list-item-avatar size="32">
                      <v-btn
                        @mouseenter="hover = true"
                        @mouseleave="hover = false"
                        v-if="item.canal != '' && item.estatus != 'CERRADA'"
                        color="white"
                        @click="openCanal(item.canal)"
                        style="
													z-index: 4;
													border: 3px solid #eb262c;
													background: white;
												"
                        icon
                      >
                        <v-icon color="#eb262c">mdi-play</v-icon>
                      </v-btn>
                      <v-img
                        v-else-if="item.tipo == 'HIPODROMO'"
                        src="../../assets/img/hipo.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'GALGODROMO'"
                        src="../../assets/img/galgos.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'CARRETA'"
                        src="../../assets/img/carretas.svg"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="mb-0">
                        <b class="font-weight-medium caption">{{
                          item.nombre
                        }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle style="line-height: 0.7rem">
                        <small class="d-flex align-center">
                          <span class="font-weight-medium"
                            >CARRERA {{ item.idcarrera }}</span
                          >
                          <span class="ml-1" v-if="item.mtp != 0">-</span>
                          <span
                            v-if="
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            "
                            class="white--text ml-1 font-weight-medium"
                            :class="item.mtp == 0 ? 'animate-mtp' : ''"
                            >[ {{ item.mtp }} MTP ]</span
                          >
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <div class="d-flex align-center">
                        <v-chip
                          :class="
                            item.estatus == 'CORRIENDO' ? 'black--text' : ''
                          "
                          x-small
                          dark
                          :color="color(item.estatus)"
                          style="padding: 10px 10px"
                        >
                          <b
                            :style="
                              marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : ''
                            "
                            class="font-weight-medium"
                          >
                            <small>{{ item.estatus }}</small>
                          </b>
                        </v-chip>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- <v-divider inset :key="item.nombre"></v-divider> -->
                </template>
              </v-list-item-group>
            </template>
            <template v-else>
              <div class="d-flex">
                <div class="mx-auto">
                  <h3>No hay eventos disponibles</h3>
                </div>
              </div>
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-list
            nav
            rounded
            dark
            two-line
            dense
            color="transparent"
            class="event-list"
          >
            <template v-if="carretas.length != 0">
              <v-list-item-group no-action v-model="item2" color="white">
                <template v-for="(item, idx) in carretas">
                  <v-list-item
                    :key="idx"
                    class="event-item"
                    @click="changeEvent(item, true, idx, 2)"
                  >
                    <v-list-item-avatar size="32">
                      <v-btn
                        @mouseenter="hover = true"
                        @mouseleave="hover = false"
                        v-if="item.canal != '' && item.estatus != 'CERRADA'"
                        color="white"
                        @click="openCanal(item.canal)"
                        style="
													z-index: 4;
													border: 3px solid #eb262c;
													background: white;
												"
                        icon
                      >
                        <v-icon color="#eb262c">mdi-play</v-icon>
                      </v-btn>
                      <v-img
                        v-else-if="item.tipo == 'HIPODROMO'"
                        src="../../assets/img/hipo.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'GALGODROMO'"
                        src="../../assets/img/galgos.svg"
                      ></v-img>
                      <v-img
                        v-else-if="item.tipo == 'CARRETA'"
                        src="../../assets/img/carretas.svg"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="mb-0">
                        <b class="font-weight-medium caption">{{
                          item.nombre
                        }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle style="line-height: 0.7rem">
                        <small class="d-flex align-center">
                          <span class="font-weight-medium"
                            >CARRERA {{ item.idcarrera }}</span
                          >
                          <span
                            class="ml-1"
                            v-if="item.mtp != 0 || item.mtp != ''"
                            >-</span
                          >
                          <span
                            v-if="
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            "
                            class="white--text ml-1 font-weight-medium"
                            :class="item.mtp == 0 ? 'animate-mtp' : ''"
                            >[ {{ item.mtp }} MTP ]</span
                          >
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <div class="d-flex align-center">
                        <v-chip
                          :class="
                            item.estatus == 'CORRIENDO' ? 'black--text' : ''
                          "
                          x-small
                          dark
                          :color="color(item.estatus)"
                          style="padding: 10px 10px"
                        >
                          <b
                            :style="
                              marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : ''
                            "
                            class="font-weight-medium"
                          >
                            <small>{{ item.estatus }}</small>
                          </b>
                        </v-chip>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- <v-divider inset :key="item.nombre"></v-divider> -->
                </template>
              </v-list-item-group>
            </template>
            <template v-else>
              <div class="d-flex">
                <div class="mx-auto">
                  <h3>No hay eventos disponibles</h3>
                </div>
              </div>
            </template>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    hipodromos: Array,
    galgos: Array,
    carretas: Array,
  },
  data() {
    return {
      shown: false,
      tab: null,
      item: 0,
      item1: null,
      item2: null,
      i: 0,
      i1: null,
      i2: null,
      hover: false,
      selectedItem: 0,
      items: [
        {
          text: "HIPISMO 24/7",
          icon: "mdi-horse-variant",
          ruta: "/virtuales/horse",
        },
        { text: "GALGOS 24/7", icon: "mdi-dog-side", ruta: "/virtuales/dog8" },
        /* { text: "CARRETAS 24/7", icon: "mdi-horse-variant", ruta:"/virtuales/harness" }, */
        { text: "KARTS 24/7", icon: "mdi-car-sports", ruta: "/virtuales/kart" },
        { text: "RULETA 24/7", icon: "mdi-poker-chip", ruta: "/virtuales/rtt" },
        { text: "KICKBOX 24/7", icon: "mdi-karate", ruta: "/virtuales/wgp" },
      ],
    };
  },
  computed: {
    // eventos() {
    // 	let array = this.events;
    // 	return array.sort((a, b) => {
    // 		return a.mtp - b.mtp;
    // 	});
    // },
    // hipodromos() {
    // 	let array;
    // 	let abiertas = [];
    // 	let corriendo = [];
    // 	let cerradas = [];
    // 	let otras = [];

    // 	array = this.eventos.filter((e) => {
    // 		return e.tipo == "HIPODROMO";
    // 	});

    // 	array.filter((e) => {
    // 		if (e.estatus == "ABIERTA") {
    // 			abiertas.push(e);
    // 		} else if (e.estatus == "CORRIENDO") {
    // 			corriendo.push(e);
    // 		} else if (e.estatus == "CERRADA") {
    // 			cerradas.push(e);
    // 		} else {
    // 			otras.push(e);
    // 		}
    // 	});
    // 	return abiertas.concat(corriendo, cerradas, otras);
    // },
    // galgos() {
    // 	let array;
    // 	let abiertas = [];
    // 	let corriendo = [];
    // 	let cerradas = [];
    // 	let otras = [];

    // 	array = this.eventos.filter((e) => {
    // 		return e.tipo == "GALGODROMO";
    // 	});

    // 	array.filter((e) => {
    // 		if (e.estatus == "ABIERTA") {
    // 			abiertas.push(e);
    // 		} else if (e.estatus == "CORRIENDO") {
    // 			corriendo.push(e);
    // 		} else if (e.estatus == "CERRADA") {
    // 			cerradas.push(e);
    // 		} else {
    // 			otras.push(e);
    // 		}
    // 	});
    // 	return abiertas.concat(corriendo, cerradas, otras);
    // },
    // carretas() {
    // 	let array;
    // 	let abiertas = [];
    // 	let corriendo = [];
    // 	let cerradas = [];
    // 	let otras = [];

    // 	array = this.eventos.filter((e) => {
    // 		return e.tipo == "CARRETA";
    // 	});

    // 	array.filter((e) => {
    // 		if (e.estatus == "ABIERTA") {
    // 			abiertas.push(e);
    // 		} else if (e.estatus == "CORRIENDO") {
    // 			corriendo.push(e);
    // 		} else if (e.estatus == "CERRADA") {
    // 			cerradas.push(e);
    // 		} else {
    // 			otras.push(e);
    // 		}
    // 	});
    // 	return abiertas.concat(corriendo, cerradas, otras);
    // },
    marca() {
      return this.$store.state.marca;
    },
    mode() {
      return this.$store.state.mode;
    },
    colors() {
      return this.$store.state.colors;
    },
  },

  methods: {
    openCanal(v) {
      this.$emit("openChannel", v);
    },
    changeEvent(value, other, idx, type) {
      if (type == 0) {
        if (this.hover) {
          setTimeout(() => {
            this.item = this.i;
          }, 1);
        } else {
          if (this.i != idx) {
            this.$emit("changeEvent", value, other);
            this.i = idx;
          }
          setTimeout(() => {
            this.item = idx;
            this.item1 = null;
            this.item2 = null;
            this.i1 = null;
            this.i2 = null;
          }, 100);
        }
      } else if (type == 1) {
        if (this.hover) {
          setTimeout(() => {
            this.item1 = this.i1;
          }, 1);
        } else {
          if (this.i1 != idx) {
            this.$emit("changeEvent", value, other);
            this.i1 = idx;
          }
          setTimeout(() => {
            this.item = null;
            this.item1 = idx;
            this.item2 = null;
            this.i = null;
            this.i2 = null;
          }, 100);
        }
      } else {
        if (this.hover) {
          setTimeout(() => {
            this.item2 = this.i2;
          }, 1);
        } else {
          if (this.i2 != idx) {
            this.$emit("changeEvent", value, other);
            this.i2 = idx;
          }
          setTimeout(() => {
            this.item = null;
            this.item1 = null;
            this.item2 = idx;
            this.i = null;
            this.i1 = null;
          }, 100);
        }
      }
    },
    color(v) {
      if (v == "ABIERTA") {
        return this.colors.primary;
      } else if (v == "CORRIENDO") {
        return this.colors.warning;
      } else {
        return this.colors.error;
      }
    },
  },
};
</script>

<style>
.eventos {
  /* background-image: linear-gradient(0deg, #0a346a 0%, #1156b0 100%); */
  height: calc(100vh - 90px);
}
.event-item {
  height: 48px !important;
  min-height: 48px !important;
}
.event-list {
  overflow-y: overlay;
  height: calc(100vh - 130px);
}
@media (min-width: 960px) {
  .event-list::-webkit-scrollbar {
    width: 5px !important;
    background: #ffffff1a !important;
    border-radius: 30px;
  }
  .event-list::-webkit-scrollbar-thumb {
    background: #ffffff !important;
    border-radius: 30px;
  }
  .event-list::-webkit-scrollbar-thumb:hover {
    background: #f1f1f1 !important;
  }
}
</style>
