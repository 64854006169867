import Vue from 'vue'
import VueRouter from 'vue-router'
// import Index from '../views/Index.vue'
// import Promociones from '../views/Promociones.vue'
import Bet from '../views/Bet.vue'
import Results from '../views/Results.vue'
import Profile from '../views/Profile.vue'
import Dashboard from '../views/profile/Dashboard.vue'
import WinningTickets from '../views/profile/WinningTickets.vue'
import Referidos from '../views/profile/Referidos.vue'
import Operations from '../views/profile/Operations.vue'
import Plays from '../views/profile/Plays.vue'
import Accounts from '../views/profile/Accounts.vue'
import Cartera from '../views/profile/Cartera.vue'
import Withdrawals from '../views/profile/Withdrawals.vue'
import Recharges from '../views/profile/Recharges.vue'
import Supportick from '../views/profile/Supportick.vue'
//import Casinoindex from '../views/Casinoindex.vue'
// import GameType from '../views/GameType.vue'
import Casino from '../views/Casino.vue'
import Slot from '../views/Slot.vue'
import OpenGame from '../views/OpenGame.vue'
import OpenGameP from '../views/OpenGamePragmatic.vue'
import SportJugadas from '../views/profile/SportJugadas.vue'

import Virtualgame from '../views/virtualgame.vue'
import Inh from '../views/inh.vue'
import Inhsport from '../views/inhsport.vue'
import Sportach from '../views/Sportach.vue'
import Quiniela from '../views/Quiniela.vue'
import Casinoach from '../views/Casinoach.vue'
import Macuare from '../views/Macuare.vue'
import Navegante from '../views/Navegante.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/terminos',
    name: 'Terminos y condiciones',
    component: () => import('../views/Terminos.vue')
  },
  {
    path: '/promoflash',
    name: 'Promo Maquinitas',
    component: () => import('../views/Promoflash.vue')
  },
  {
    path: '/concurso',
    name: 'Concurso',
    component: () => import('../views/Concurso.vue')
  },
  {
    path: '/depositos',
    name: 'Depositos y Retiros',
    component: () => import('../views/Depositos.vue')
  },
  {
    path: '/promociones',
    name: 'Promociones',
    component: () => import('../views/Promociones.vue')
  },
  {
    path: '/gametype',
    name: 'Tipo de juego',
    component: () => import('../views/GameType.vue')
  },
  {
    path: '/casino',
    name: 'Juegos',
    component: Slot
  },
  {
    path: '/slot',
    name: 'Slot',
    component: Casino
  },
  {
    path: '/sport',
    name: 'Sport',
    component: Sportach
  },
  {
    path: '/sportach',
    name: 'Sport',
    component: Sportach
  },
  {
    path: '/quiniela',
    name: 'Quiniela',
    component: Quiniela
  },
  {
    path: '/marcas',
    name: 'Marcas',
    component: Quiniela
  },
  {
    path: '/inh',
    name: 'Inh',
    component: Inh
  },
  {
    path: '/casinoindex',
    name: 'Casinoindex',
    component: Casinoach
  },
  {
    path: '/macuare',
    name: 'Macuare',
    component: Macuare
  },{
    path: '/navegante',
    name: 'Navegante',
    component: Navegante
  },
  {
    path: '/inhsport',
    name: 'Inhsport',
    component: Inhsport
  },
  {
    path: '/virtuales/:game',
    name: 'Virtuales',
    component: Virtualgame
  },
  {
    path: '/game/:name/:code',
    name: 'JuegoP',
    component: OpenGame
  },
  {
    path: '/gamep/:name/:code',
    name: 'Juego',
    component: OpenGameP
  },
  {
    path: '/bet',
    name: 'Bet',
    component: Bet
  },
  {
    path: '/sportjugadas',
    name: 'Jugadas Deportivas',
    component: SportJugadas
  },
  {
    path: '/channels',
    name: 'Channels',
    component: Bet
  },
  {
    path: '/results',
    name: 'Results',
    component: Results
  },
  {
    path: '/profile',
    component: Profile,
    children: [
      {
        path: '',
        name: 'Perfil',
        component: Dashboard
      },
      {
        path: '/rewards',
        name: 'Tickets premiados',
        component: WinningTickets
      },
      {
        path: '/referidos',
        name: 'Referidos',
        component: Referidos
      },
      {
        path: '/operations',
        name: 'Operaciones',
        component: Operations
      },
      {
        path: '/plays',
        name: 'Jugadas',
        component: Plays
      },
      {
        path: '/accounts',
        name: 'Mis cuentas',
        component: Accounts,
      },
      {
        path: '/accounts/:idx/:id',
        name: 'Cartera',
        component: Cartera,
      },
      {
        path: '/withdrawals',
        name: 'Historial de retiros',
        component: Withdrawals
      },
      {
        path: '/recharges',
        name: 'Historial de recargas',
        component: Recharges
      },
      {
        path: '/supportick',
        name: 'Supportick',
        component: Supportick
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
